"use client";

import { createContext, useContext } from "react";
import {
  ComponentController,
  useComponentController,
} from "./ComponentsContext.utils";

export interface ComponentsState {
  bookingFlowDateDrawer: ComponentController;
  cancelBookingModal: ComponentController;
  completeBookingDrawer: ComponentController;
  coworkerDrawer: ComponentController;
  datePickerDrawer: ComponentController;
  learnMoreDrawer: ComponentController;
  resourceInfoDrawer: ComponentController;
  shareLocationModal: ComponentController;
  timePickerDrawer: ComponentController;
  workspacesMapDrawer: ComponentController;
  addCardDrawer: ComponentController;
  bookingDetailsDrawer: ComponentController;
}

const ComponentsStateContext = createContext<ComponentsState>(
  {} as ComponentsState,
);

ComponentsStateContext.displayName = "Components state context";

export const ComponentsStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ComponentsStateContext.Provider
      value={{
        bookingFlowDateDrawer: useComponentController(),
        cancelBookingModal: useComponentController(),
        completeBookingDrawer: useComponentController(),
        coworkerDrawer: useComponentController(),
        datePickerDrawer: useComponentController(),
        learnMoreDrawer: useComponentController(),
        resourceInfoDrawer: useComponentController(),
        shareLocationModal: useComponentController(),
        timePickerDrawer: useComponentController(),
        workspacesMapDrawer: useComponentController(),
        addCardDrawer: useComponentController(),
        bookingDetailsDrawer: useComponentController(),
      }}
    >
      {children}
    </ComponentsStateContext.Provider>
  );
};

export const useComponentsState = (): ComponentsState => {
  return useContext(ComponentsStateContext);
};
