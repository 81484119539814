import { Timespan } from './Timespan';
import { TheDateTime } from './TheDateTime';
import { TheDay } from './TheDay';
import { TheTime } from './TheTime';
import { Duration } from 'date-fns';

export class TheInterval {
  private readonly timespan: Timespan;
  private readonly interval: Duration;
  readonly day: TheDay;
  readonly start: TheDateTime;
  readonly end: TheDateTime;

  constructor(
    startTime: TheTime,
    endTime: TheTime,
    day: TheDay = TheDay.now(),
  ) {
    if (!startTime || !endTime) {
      throw new Error(
        `Invalid arguments: startTime: ${startTime}, endTime: ${endTime}`,
      );
    }

    this.day = day;

    this.timespan = {
      fromTime: startTime,
      toTime: endTime,
    };

    this.interval = TheTime.intervalToDuration({
      start: startTime,
      end: endTime,
    });

    this.start = new TheDateTime(day, startTime);
    this.end = new TheDateTime(day, endTime);
  }

  static fromDuration(startDateTime: string, durationInMinutes: number) {
    if (!isSet(startDateTime) || !isSet(durationInMinutes)) {
      return null;
    }

    const day = TheDay.parse(startDateTime);
    const startTime = TheTime.parse(startDateTime);
    const endTime = startTime.addMinutes(durationInMinutes);

    return new TheInterval(startTime, endTime, day);
  }

  static fromHourly(
    hour: {
      hour: number;
      minute: number;
    },
    durationInMinutes: number,
    day: TheDay = TheDay.now(),
  ) {
    if (
      !isSet(hour?.hour) ||
      !isSet(hour?.minute) ||
      !isSet(durationInMinutes)
    ) {
      return null;
    }

    const startTime = new TheTime(hour.hour, hour.minute);
    const endTime = startTime.addMinutes(durationInMinutes);

    return new TheInterval(startTime, endTime, day);
  }

  static fromTimespan(timespan: Timespan, day: TheDay = TheDay.now()) {
    if (!timespan?.fromTime || !timespan?.toTime) {
      return null;
    }

    return new TheInterval(timespan.fromTime, timespan.toTime, day);
  }

  static fromInterval(interval: TheInterval, day: TheDay) {
    return new TheInterval(interval.start.time, interval.end.time, day);
  }

  static fromString(startTime: string, endTime: string) {
    let date = TheDay.tryParse(startTime);
    if (!date) {
      date = TheDay.now();
    }

    return new TheInterval(
      TheTime.parse(startTime),
      TheTime.parse(endTime),
      date,
    );
  }

  static fromTheDateTime(start: TheDateTime, end: TheDateTime) {
    if (!start || !end) {
      throw new Error(`Invalid arguments: start: ${start}, end: ${end}`);
    }

    return new TheInterval(start.time, end.time, start.date);
  }

  isAfterTheDateTime(date: TheDateTime): boolean {
    return this.start.isAfter(date);
  }

  isAfterOrEqualTheDateTime(date: TheDateTime): boolean {
    return this.start.isAfter(date) || this.start.isEqual(date);
  }

  isBeforeTheDateTime(date: TheDateTime): boolean {
    return this.end.isBefore(date);
  }

  isBeforeOrEqualTheDateTime(date: TheDateTime): boolean {
    return this.end.isBefore(date) || this.end.isEqual(date);
  }

  isTheDateTimeInsideInterval(date: TheDateTime): boolean {
    return date.isAfterOrEqual(this.start) && date.isBeforeOrEqual(this.end);
  }

  isOverlapping(interval: TheInterval): boolean {
    if (!interval) {
      return false;
    }

    const start = this.start.time;
    const end = this.end.time;

    return (
      (start.isAfter(interval.start.time) &&
        !start.isAfter(interval.end.time)) ||
      (end.isAfter(interval.start.time) && !end.isAfter(interval.end.time)) ||
      (start.isEqual(interval.start.time) && end.isEqual(interval.end.time))
    );
  }

  isEqual(interval: TheInterval): boolean {
    if (!interval) {
      return false;
    }

    return this.start.isEqual(interval.start) && this.end.isEqual(interval.end);
  }

  isEqualOrContains(interval: TheInterval): boolean {
    if (!interval) {
      return false;
    }

    if (this.isEqual(interval)) {
      return true;
    }

    const start = this.start.time;
    const end = this.end.time;

    const isContained =
      interval.start.time.isAfter(start) && interval.end.time.isBefore(end);
    if (isContained) {
      return true;
    }

    return (
      (interval.start.time.isEqual(start) && interval.end.time.isBefore(end)) ||
      (interval.start.time.isAfter(start) && interval.end.time.isEqual(end))
    );
  }

  getIntersections<T extends { interval: TheInterval }>(items: T[]): T[] {
    if (!items || items.length === 0) {
      return [];
    }

    return items.filter((item) => this.isOverlapping(item.interval));
  }

  getTimespan(): Timespan {
    return this.timespan;
  }

  getDurationInHours(): number {
    const days = this.interval.days || 0;
    const hours = this.interval.hours || 0;
    const minutes = this.interval.minutes || 0;
    const seconds = this.interval.seconds || 0;

    return days * 24 + hours + minutes / 60 + seconds / 3600;
  }

  getDurationInMinutes(): number {
    return this.getDurationInHours() * 60;
  }

  toDurationAndStart(): {
    durationInMinutes: number;
    startDateTime: string;
  } {
    return {
      durationInMinutes: this.end.time.getMinutesDifference(this.start.time),
      startDateTime: this.start.toString(),
    };
  }

  toString(): string {
    return getTimeLabel(
      {
        fromTime: this.timespan.fromTime,
        toTime: this.timespan.toTime,
      },
      this.interval,
    );
  }

  toShortString(): string {
    return `${this.timespan.fromTime.toString()} - ${this.timespan.toTime.toString()}`;
  }
}

const isSet = (value: any) => value !== null && value !== undefined;

const getTimeLabel = (selectedTime: Timespan, interval: Duration) => {
  if (!selectedTime?.fromTime || !selectedTime?.toTime) {
    return '';
  }

  const base = `${selectedTime.fromTime.toString()} - ${selectedTime.toTime.toString()}`;

  if (!interval.hours || interval.hours === 0) {
    if (!interval.minutes || interval.minutes === 0) {
      return base;
    }

    return `${base} · ${interval.minutes} minutes`;
  }

  if (!interval.minutes || interval.minutes === 0) {
    if (interval.hours === 1) {
      return `${base} · 1 hour`;
    }

    return `${base} · ${interval.hours} hours`;
  }

  return `${base} · ${interval.hours} h ${interval.minutes} min`;
};
